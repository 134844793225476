<template>
	<div class="mall">
		<div class="mall-top">
			<div class="mall-hd">
				<div class="integral">
					<span>当前积分</span>
					<div>{{ integral }}</div>
				</div>
				<router-link to="/rule" tag="div" class="rule-link">
					<img src="@/assets/mall-star.png" alt="">
					<span>积分规则</span>
				</router-link>
			</div>
		</div>
		<div class="mall-nav">
			<router-link to="/PointsDetailed" tag="div" class="nav-link">
				<img src="@/assets/jf1.png" alt="">
				<span>积分明细</span>
			</router-link>
			<router-link to="/MallOrder" tag="div" class="nav-link">
				<img src="@/assets/jf2.png" alt="">
				<span>积分订单</span>
			</router-link>
		</div>
		<div class="title"><span>—</span>积分兑好礼<span>—</span></div>
		<div class="classify-box">
			<div class="flex-box">
				<div :class="['classify-link',{'ac':classifyTab == 0}]" @click="classifyTab = 0">所有商品</div>
				<div :class="['classify-link',{'ac':classifyTab == item.cate_id}]" @click="classifyTab = item.cate_id" v-for="(item, index) in classifyList" :key="index">{{ item.cate_name }}</div>
			</div>
		</div>
		<goods-list :goodsList="goodsList" :isLoad='isLoad' :loadLastText='loadLastText' @getMore='getIntegralGoodsList'></goods-list>
		
		<!-- 客服组件 -->
		<customer-service></customer-service>
	</div>
</template>
<style lang="scss" scoped>
	.mall {
		.mall-top {
			background: #f6f6f6;
			padding-top: .27rem;

			.mall-hd {
				width: 94vw;
				border-radius: 10px 10px 0 0;
				margin: 0 3vw;
				background: url(../../assets/mall-bg.png) no-repeat 100% 100%;
				height: 1.9rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: #5d4b00;

				.integral {
					font-size: .5rem;
					margin-left: .93rem;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					text-align: center;

					span {
						font-size: .28rem;
						margin-bottom: .1rem;
					}
				}

				.rule-link {
					display: flex;
					align-items: center;
					height: .55rem;
					width: 2rem;
					border-radius: .55rem 0 0 .55rem;
					background: linear-gradient(to right, rgba(255, 255, 255, .5), rgba(255, 255, 255, 0));

					img {
						width: .5rem;
						height: .5rem;
						border-radius: 50%;
						margin-right: .15rem;
						margin-left: .05rem;
					}

					span {
						font-size: .26rem;
					}
				}
			}
		}

		.mall-nav {
			display: flex;
			align-items: center;
			height: 2rem;
			border-bottom: 10px solid #f6f6f6;



			.nav-link {
				font-size: .26rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 50vw;

				img {
					width: .92rem;
					height: .92rem;
					margin-bottom: .17rem;
				}
			}
		}

		.title {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 1.2rem;
			font-size: .3rem;
			width: 100%;

			span {
				color: #ff7a01;
				margin: 0 .2rem;
				white-space: nowrap;
			}
		}

		.classify-box {
			width: 100vw;
			overflow-x: scroll;

			.flex-box {
				display: flex;
				align-items: center;
				width: max-content;

				.classify-link {
					font-size: .3rem;
					padding: 0 5px;
					white-space: nowrap;
					margin: 0 3vw;
					line-height: .88rem;
					border-bottom: .05rem solid transparent;
				}

				.ac {
					color: #ff7a01;
					border-bottom: .05rem solid #ff7a01;
				}
			}
		}
		
	}
</style>
<script>
import { getIntegral, getIntegralClassifyList,	getIntegralGoodsList } from "@/http/api";
import CustomerService from "@/views/home/children/CustomerService";
import GoodsList from "./MallChildren/GoodsList";

export default {
	components: {
		CustomerService,
		GoodsList
	},
	data() {
		return {
			userInfo: {},
			integral: '',
			classifyList: [],
			classifyTab: 0,
			goodsList: [],
			pages: 1,
			loadLastText: false,
			isLoad: false
		};
	},
	mounted() {
		if ((this.userInfo = this.$login.isLogin(window.location.href))) {
			// console.log(this.userInfo);
			this.getIntegral();
			this.getIntegralClassifyList();
			this.getIntegralGoodsList();
		}
	},
	methods: {
		// 获取用户账户积分
		async getIntegral() {
			const res = await getIntegral({
				user_id: this.userInfo.user_id
			});
			// console.log(res);
			this.integral = res.integral;
		},
		// 获取积分商城分类
		async getIntegralClassifyList() {
			const res = await getIntegralClassifyList({});
			// console.log(res);
			this.classifyList = res;
		},
		// 获取积分商品列表
		async getIntegralGoodsList() {
			const res = await getIntegralGoodsList({
				pages: this.pages,
				cate_id: this.classifyTab
			});
			// console.log(res);
			if(res.code == '200'){
				this.isLoad = true;
				this.goodsList = this.goodsList.concat(res.data);
				if(res.data.length < res.numPage){
					this.loadLastText = false;
				}else {
					this.pages ++;
					this.loadLastText = true;
				}
			}
		},
	},
	watch: {
		classifyTab() {
			this.pages = 1;
			this.goodsList = [];
			this.isLoad = false;
			this.loadLastText = false;
			this.getIntegralGoodsList();
		}
	},
	computed: {

	},
	filters: {

	}
};
</script>
