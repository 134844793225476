<template>
  <router-link :to="'/GoodsDetails?id=' + item.goods_id" tag="div" class="goods-grids">
    <img :src="item.cover_imgurl" alt="" />
    <p class="name">{{ item.goods_name }}</p>
    <p class="score"><span>{{ item.buy_points }}</span>积分</p>
  </router-link>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.goods-grids {
  width: 44vw;
  margin-bottom: 20px;

  img {
    width: 44vw;
    height: 44vw;
    border: 1px solid #ebebeb;
    margin-bottom: 10px;
    object-fit: cover;
  }

  .name {
    font-size: 0.28rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .score {
    color: #ff2200;
    font-size: 0.24rem;

    span {
      font-size: 0.32rem;
      font-weight: bold;
      line-height: 2;
    }
  }
}
</style>
