<template>
  <div v-if="isLoad">
    <template v-if="goodsList.length > 0">
      <div class="goods-lists">
        <goods-item v-for="(item, index) in goodsList" :key="index" :item='item'></goods-item>
      </div>
      <load-more :loadLastText='loadLastText' @getMore='getMore'></load-more>
    </template>
    <no-data v-else content='这里没有数据'></no-data>
  </div>
</template>

<script>
import GoodsItem from './GoodsItem';
export default {
  name: "",
  components: {
    GoodsItem
  },
  props: {
    goodsList: {
      type: Array,
      default() {
        return [];
      },
    },
    loadLastText: {
      type: Boolean,
      default() {
        return true
      }
    },
    isLoad: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    getMore() {
      this.$emit('getMore')
    }
  },
};
</script>

<style scoped lang="scss">
.goods-lists {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 3vw 3vw 0;
  box-sizing: border-box;
}
</style>
